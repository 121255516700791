import React from 'react';

import k6 from '../assets/karate/gallery/6.jpg';
import Title from './shared/Title';

const Contact = () => {
  return (
    <section id="contact" className="py-8 mt-12 bg-white">
      <Title before="" black="Co" title="ntact" desc="Et documents" />

      <aside className="flex items-center justify-between overflow-hidden">
        <div className="p-8 md:p-12 lg:px-16 lg:py-24">
          <div className="max-w-xl mx-auto space-y-2 text-center sm:text-left">
            <div className="space-y-4 text-primary">
              <p className="mx-2">
                <b>ecolecksd@gmail.com</b>
              </p>

              <div className="mx-2">
                <a
                  href={process.env.PUBLIC_URL + 'flyers/flyers-2025.pdf'}
                  target="_blank"
                  title="Informations"
                  rel="noopener noreferrer"
                >
                  Des infos sur le Flyer
                </a>
              </div>
              <div className="mx-2">
                <a
                  href={
                    process.env.PUBLIC_URL +
                    'formulaires/FORMULAIRE-DEMANDE-DE-LICENCE-INTERNET-2024-2025.pdf'
                  }
                  target="_blank"
                  title="Formulaires"
                  rel="noopener noreferrer"
                >
                  Formulaire d'inscription
                </a>
              </div>
              <div className="mx-2">
                <a
                  href={
                    process.env.PUBLIC_URL +
                    'formulaires/REGLEMENT-INTERIEUR_24-25.pdf'
                  }
                  target="_blank"
                  title="Formulaires"
                  rel="noopener noreferrer"
                >
                  Réglement intérieur
                </a>
              </div>
              <div className="mx-2">
                <a
                  href={
                    process.env.PUBLIC_URL +
                    'formulaires/attestation-Reglement-interieur-ecksd.pdf'
                  }
                  target="_blank"
                  title="Formulaires"
                  rel="noopener noreferrer"
                >
                  Attestation du réglement intérieur
                </a>
              </div>
              <div className="mx-2">
                <a
                  href={
                    process.env.PUBLIC_URL +
                    'formulaires/ATTESTATION-SUR-LHONNEUR-SPORTIF-MINEUR_ANNEXE2_M_2.pdf'
                  }
                  target="_blank"
                  title="Formulaires"
                  rel="noopener noreferrer"
                >
                  Attestation certificat médical enfants
                </a>
              </div>
            </div>
          </div>
        </div>

        <img
          alt="#"
          src={k6}
          className="object-cover object-center w-full h-80 rounded-tl-[60px] rounded-bl-[60px] self-end"
        />
      </aside>
    </section>
  );
};

export default Contact;
