import React from 'react'
import ruepaix from "../assets/lieu/8ruepaix.png";
import arthe from "../assets/lieu/arthe.png";
import Title from './shared/Title';

const Cours = () => {
  return (
    <section id="cours" className="py-8 my-12 bg-white">
      <div className="container mx-auto ">
        <Title before="les" black="co" title="urs" desc="Deux endroits" />

        <div className="flex flex-col justify-center space-x-0 space-y-4 md:flex-row md:flex-wrap md:justify-around md:items-start md:space-x-4 md:space-y-0">
          <div className="flex flex-col justify-center space-y-2">
            <h3 className="font-bold text-center">LIEUX DES COURS</h3>
            <p className="text-center">
              Gymnase du cosec ceyrat, 8 rue de la paix
            </p>

            <a
              href="https://www.openstreetmap.org/note/new?lat=45.73398&lon=3.06692#map=16/45.7334/3.0709&layers=N"
              className="animate-grow"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ruepaix}
                className="object-cover rounded h-80"
                alt="rue de la paix"
              />
            </a>

            <div className="flex flex-col space-y-2 ">
              <h2 className="font-bold text-center underline md:text-left">
                Karaté adulte
              </h2>
              <p className="text-center"> Lundi à 20h-21h30</p>
              <p className="font-bold text-center underline md:text-left">
                Karaté préparation aux grades
              </p>
              <p className="text-center">Lundi 19h à 19h45</p>
              <p className="font-bold text-center underline md:text-left">
                Karaté
              </p>
              <p className="text-center">Jeudi 18h à 19h</p>
              <p className="font-bold text-center underline md:text-left">
                Cours renforcement musculaire
              </p>
              <p className="text-center">Vendredi à 19h15</p>
            </div>
          </div>

          <div className="block border-b md:hidden border-secondary"></div>

          <div className="flex flex-col justify-center space-y-2">
            <h3 className="font-bold text-center">LIEUX DES COURS</h3>
            <p className="text-center">Artenium, 4 parc de l'artière ceyrat</p>

            <a
              href="https://www.openstreetmap.org/note/new?lat=45.73542&lon=3.06914#map=17/45.73437/3.06903&layers=N"
              className="animate-grow"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={arthe}
                className="object-cover rounded h-80"
                alt="Artenium"
              />
            </a>

            <div className="flex flex-col space-y-2 ">
              <h2 className="font-bold text-center underline md:text-left">
                Self défense
              </h2>
              <p className="text-center"> Mercredi 19h30 à 21h</p>
              <p className="font-bold text-center underline md:text-left">
                Karaté enfants
              </p>
              <p className="text-center">Mercredi 8 - 9 ans 17h45 à 18h30</p>
              <p className="text-center">Mercredi 10 - 12+ ans 18h30 à 19h30</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cours